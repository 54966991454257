<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="90px" size="mini">
          <el-form-item label="上级分销商:">
            {{ dealerInfo.MemberName || "无" }}
          </el-form-item>

          <el-form-item label="" label-width="0">
            <div class="member-select">
              <span>所属会员：</span>
              <DfMember
                v-model="word"
                placeholder="请输入会员姓名/手机号"
                @change="onSelectMember"
              ></DfMember>
            </div>
          </el-form-item>

          <template v-if="memberInfo.MemberGuid">
            <el-form-item label="会员姓名:">
              {{ memberInfo.MemberName }}
            </el-form-item>

            <el-form-item label="会员手机:">
              {{ memberInfo.MemberPhone }}
            </el-form-item>

            <!-- <el-form-item label="所属门店：">
              {{ memberInfo.StoresName }}
            </el-form-item> -->
          </template>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" :disabled="submitDisabled" @click="addDealer"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Dealer from "@/api/dealer";

export default {
  data() {
    return {
      rowSpan: 20,
      colSpan: 8,
      title: "",
      word: "",
      dealerInfo: {},
      memberInfo: {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
        StoresName: "",
      },
      showDialog: false,
      submitDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 添加分销商
    async addDealer() {
      let { memberInfo, dealerInfo } = this;
      if (!memberInfo.MemberGuid) {
        return this.$message.error("请选择分销商");
      }

      let submitData = {
        member_guid: memberInfo.MemberGuid,
        dealer_id: dealerInfo.DealerId || 0,
      };
      this.submitDisabled = true;
      try {
        let { errcode } = await Dealer.addDealer(submitData);
        if (errcode === 0) {
          this.$message.success("添加成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 选中分销商
    onSelectMember(event) {
      this.memberInfo = event;
    },

    // 重置页面数据
    resetPageData() {
      this.word = "";
      this.memberInfo = {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
        StoresName: "",
      };
    },

    // 打开对话框
    onShowDialog(event) {
      this.dealerInfo = JSON.parse(JSON.stringify(event));
      this.title = event.MemberGuid ? "添加下级分销商" : "添加分销商";
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .member-select {
    .flex-row;
    padding-left: 5px;
    box-sizing: border-box;
  }
}
</style>
