<template>
  <div>
    <el-dialog
      title="下级买家列表"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-table
          :data="tableList"
          max-height="400px"
          size="mini"
          border
          stripe
          v-loading="loading"
        >
          <el-table-column prop="" label="会员头像" width="80px">
            <template slot-scope="scope">
              <div class="member-info">
                <div v-if="!scope.row.MemberHead" class="el-image">
                  {{ scope.row.MemberName.slice(0, 1) }}
                </div>
                <el-image
                  v-else
                  :src="scope.row.MemberHead"
                  fit="fill"
                  :lazy="true"
                ></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="MemberName" label="会员名称"></el-table-column>
          <el-table-column
            prop="MemberPhone"
            label="会员手机"
          ></el-table-column>
          <el-table-column prop="" label="注册时间">
            <template slot-scope="scope">{{
              scope.row.CreateTime
                ? util.dateFormat(
                    "YYYY-mm-dd HH:MM:SS",
                    new Date(scope.row.CreateTime * 1000)
                  )
                : ""
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                v-if="submitData.level_num === 1"
                type="danger"
                size="mini"
                :disabled="deleteDisabled"
                @click="deleteBuyer(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :page-sizes="[5, 10, 15, 20]"
            :page-size="submitData.page_size"
            :current-page="submitData.page_index"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalRecord"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onCloseDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Dealer from "@/api/dealer.js";

export default {
  data() {
    return {
      totalRecord: 0,
      submitData: {
        word: "",
        dealer_id: "",
        level_num: "",
        page_index: 1,
        page_size: 10,
      },
      tableList: [],
      loading: false,
      showDialog: false,
      deleteDisabled: false,
    };
  },

  methods: {
    // 获取下级买家列表
    async getDealerLowerList() {
      this.loading = true;
      try {
        let { data } = await Dealer.getDealerLowerList(this.submitData);
        this.tableList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除买家
    deleteBuyer(event) {
      this.$confirm("是否确定删除此买家?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.deleteDisabled = true;
        try {
          let { errcode } = await Dealer.deleteBuyer({
            member_guid: event.MemberGuid,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getDealerLowerList();
            this.$emit("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.deleteDisabled = false;
        }
      });
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getDealerLowerList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getDealerLowerList();
    },

    // 打开对话框
    onShowDialog(event, levelNum) {
      let param = JSON.parse(JSON.stringify(event));
      this.submitData = {
        ...this.submitData,
        dealer_id: param.DealerId,
        level_num: levelNum,
      };
      this.$nextTick(() => {
        this.getDealerLowerList();
        this.showDialog = true;
      });
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-table {
    .member-info {
      .flex-row;

      .el-image {
        .flex-col;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-size: 22px;
        background: #eee;
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: right;

    .el-pagination {
      margin-top: 10px;
    }
  }
}
</style>
