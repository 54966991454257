<template>
  <div class="distributor">
    <div class="df-row">
      <div class="df-col">
        <el-select v-model="submitData.delete" placeholder="请选择" size="mini">
          <el-option
            v-for="(item, index) in [
              { Name: '正常', Value: 0 },
              { Name: '删除', Value: 1 },
            ]"
            :key="index"
            :label="item.Name"
            :value="item.Value"
          ></el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.word"
          placeholder="请输入昵称/姓名/手机号"
          size="mini"
          clearable
          @keyup.enter.native="onSearch"
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch"
          >查 询</el-button
        >
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddDistributor"
          >添加分销商</el-button
        >
      </div>
    </div>

    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      size="mini"
      border
      stripe
      v-loading="loading"
    >
      <el-table-column prop="DealerId" label="分销商ID" width="90">
      </el-table-column>
      <el-table-column prop="" label="会员">
        <template slot-scope="scope">
          <div class="member-info">
            <div v-if="!scope.row.MemberHead" class="el-image">
              {{ scope.row.MemberName.slice(0, 1) }}
            </div>
            <el-image
              v-else
              :src="scope.row.MemberHead"
              fit="fill"
              :lazy="true"
            ></el-image>
            <div class="info">
              <span>{{ scope.row.MemberName }}</span>
              <span>{{ scope.row.MemberPhone }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="推荐人" width="120">
        <template slot-scope="scope">
          <div class="from-dealer-info" v-show="scope.row.FromDealerId">
            <span>{{ scope.row.FromDealerName }}</span>
            <span>(ID：{{ scope.row.FromDealerId }})</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="累计佣金/可提现佣金">
        <template slot-scope="scope">
          <div class="balance-info">
            <span>累计佣金：{{ scope.row.Balance }}</span>
            <span>可提现佣金：{{ scope.row.BalanceAvailable }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="下级买家">
        <template slot-scope="scope">
          <div class="count-info">
            <span @click="onBuyerList(scope.row, 1)"
              >一级：{{ scope.row.FirstCount }}</span
            ><br />
            <span @click="onBuyerList(scope.row, 2)"
              >二级：{{ scope.row.SecondCount }}</span
            ><br />
            <span @click="onBuyerList(scope.row, 3)"
              >三级：{{ scope.row.ThirdCount }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="成为时间">
        <template slot-scope="scope">
          {{
            scope.row.CreateTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.CreateTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="450">
        <template v-if="!scope.row.DeleteMark" slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="onAddDistributor(scope.row)"
            >添加下级分销商</el-button
          >
          <el-button type="success" size="mini" @click="onAddBuyer(scope.row)"
            >添加下级买家</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="onSelectMember(scope.row)"
            >打款到会员卡</el-button
          >
          <el-button
            type="danger"
            size="mini"
            :disabled="deleteDisabled"
            @click="deleteDealer(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <!-- 添加分销商对话框 -->
    <AddDistributorDialog
      ref="addDistributorDialog"
      @success="getDealerList"
    ></AddDistributorDialog>

    <!-- 添加买家对话框 -->
    <AddBuyerDialog
      ref="addBuyerDialog"
      @success="getDealerList"
    ></AddBuyerDialog>

    <!-- 下级买家列表对话框 -->
    <BuyerListDialog
      ref="buyerListDialog"
      @success="getDealerList"
    ></BuyerListDialog>

    <!-- 打款到会员卡 -->
    <ApplyWithdrawDialog
      ref="ApplyWithdrawDialog"
      @success=""
    ></ApplyWithdrawDialog>

    <!-- 选择会员卡 -->
    <DfMemberCardDialog
      ref="DfMemberCardDialog"
      :memberGuid="selectDealerInfo.MemberGuid"
      natureNo="0001"
      @select="onApplyWithdraw"
    ></DfMemberCardDialog>
  </div>
</template>

<script>
import Dealer from "@/api/dealer.js";

import AddDistributorDialog from "./components/add-distributor-dialog.vue";
import AddBuyerDialog from "./components/add-buyer-dialog.vue";
import BuyerListDialog from "./components/buyer-list-dialog.vue";
import ApplyWithdrawDialog from "./components/apply-withdraw-dialog.vue";

export default {
  components: {
    AddDistributorDialog,
    AddBuyerDialog,
    BuyerListDialog,
    ApplyWithdrawDialog,
  },

  data() {
    return {
      tableMaxHeight: "",
      totalRecord: 0,
      submitData: {
        word: "",
        delete: 0,
        page_index: 1,
        page_size: 10,
      },
      selectDealerInfo: {},
      tableData: [],
      loading: false,
      deleteDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    // this.getDealerList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取分销商列表
    async getDealerList() {
      this.loading = true;
      try {
        let { data } = await Dealer.getDealerList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除分销商
    deleteDealer(event) {
      this.$confirm("确认删除该分销商吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.deleteDisabled = true;
        try {
          let { errcode } = await Dealer.deleteDealer({
            dealer_id: event.DealerId,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getDealerList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.deleteDisabled = false;
        }
      });
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getDealerList();
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 64);
    },

    // 打开添加分销商对话框
    onAddDistributor(event = {}) {
      this.$refs.addDistributorDialog.onShowDialog(event);
    },

    // 打开添加买家对话框
    onAddBuyer(event) {
      this.$refs.addBuyerDialog.onShowDialog(event);
    },

    // 打开选择会员卡对话框
    onSelectMember(event) {
      this.selectDealerInfo = JSON.parse(JSON.stringify(event));
      this.$refs.DfMemberCardDialog.onShowDialog();
    },

    // 打开打款到会员卡对话框
    onApplyWithdraw(event) {
      let param = {
        ...this.selectDealerInfo,
        ...event,
      };
      // console.log(this.selectDealerInfo, param);
      this.$refs.ApplyWithdrawDialog.onShowDialog(param);
    },

    // 打开下级买家列表对话框
    onBuyerList(event, levelNum) {
      this.$refs.buyerListDialog.onShowDialog(event, levelNum);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getDealerList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getDealerList();
    },
  },
};
</script>

<style lang="less" scoped>
.distributor {
  padding-top: 20px;

  .el-table {
    .member-info {
      .flex-row;

      .el-image {
        .flex-col;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-size: 22px;
        background: #eee;
      }

      .info {
        .flex-col;
        align-items: unset;
        margin-left: 10px;
      }
    }

    .from-dealer-info,
    .balance-info,
    .count-info {
      .flex-col;
      align-items: unset;
    }

    .count-info {
      display: block;
      span {
        color: #0e90d2;
        cursor: pointer;

        &:hover {
          color: #0059ac;
        }
      }
    }
  }

  .el-pagination {
    float: right;
    margin-top: 20px;
  }
}
</style>
