<template>
    <div>
        <el-dialog
            title="申请打款到会员卡"
            :visible.sync="showDialog"
            width="700px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="onCloseDialog"
        >
            <span class="content-box">
                <el-form label-width="80px" size="mini">
                    <el-row :gutter="rowSpan">
                        <el-col :span="colSpan">
                            <el-form-item label="卡号">{{ submitData.VipNo }}</el-form-item>
                        </el-col>
                        <el-col :span="colSpan">
                            <el-form-item label="卡名">{{ submitData.CardName }}</el-form-item>
                        </el-col>
                        <el-col :span="colSpan">
                            <el-form-item label="卡类型">{{ submitData.NatureName }}</el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="rowSpan">
                        <el-col :span="colSpan">
                            <el-form-item label="提现金额">
                               <el-input v-model="submitData.Amount" placeholder="请输入金额" size="mini"></el-input> 
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </span>
            <span slot="footer">
                <el-button @click="onCloseDialog">取 消</el-button>
                <el-button type="primary" @click="applyWithdrawVip">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Dealer from "@/api/dealer.js"

export default {
    data() {
        return {
            rowSpan: 24,
            colSpan: 8,
            submitData: {},
            showDialog: false,
        };
    },

    mounted() {},

    methods: {
        async applyWithdrawVip(){
            let submitData = {
                dealer_id: this.submitData.DealerId,
                amount: this.submitData.Amount,
                vip_guid: this.submitData.VipGuid
            }
            try {
                let { errcode } = await Dealer.applyWithdrawVip(submitData);
                if (errcode === 0) {
                    this.$message.success('提交成功');
                    this.$emit('success');
                    this.submitData = {}
                    this.onCloseDialog();
                }
            } catch (err) {
                console.log(err);
            }
        },

        // 打开对话框
        onShowDialog(event) {
            this.submitData = JSON.parse(JSON.stringify(event));
            // console.log(this.submitData);
            this.showDialog = true;
        },

        // 关闭对话框
        onCloseDialog() {
            this.showDialog = false;
        },
    },
};
</script>

<style lang="less" scoped>
    .content-box {
        
    }
</style>